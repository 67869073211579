/* Modal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7); /* Fondo más oscuro y semitransparente */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Asegúrate de que esté por encima del contenido */
  }
  
  .modal {
    background: #fff; /* Fondo blanco */
    padding: 20px 30px; /* Más espacio interno */
    border-radius: 10px; /* Bordes más redondeados */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Sombra suave */
    text-align: center; /* Centrar el texto */
    max-width: 400px; /* Ancho máximo para el modal */
    width: 80%; /* Ancho responsivo */
    position: relative; /* Necesario para el botón de cerrar */
    font-family: 'Arial', sans-serif; /* Tipografía clara */
    color: #333; /* Color del texto */
  }
  
  .modal p {
    margin: 0;
    font-size: 1.1em; /* Tamaño de fuente más grande */
  }
  
  .modal button {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.2em;
    color: #aaa; /* Color del botón cerrar */
    cursor: pointer;
  }
  
  .modal button:hover {
    color: #555; /* Color al pasar el cursor */
  }
  
  